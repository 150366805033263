import { initializeApp } from 'firebase/app'
import { getFirestore, Timestamp } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfigAmna = {
  apiKey: "AIzaSyBzI-R2cW1ghmBiE-_GjE-Gs4DVHeTUfU4",
  authDomain: "hana-tours-dacbc.firebaseapp.com",
  projectId: "hana-tours-dacbc",
  storageBucket: "hana-tours-dacbc.appspot.com",
  messagingSenderId: "631011937944",
  appId: "1:631011937944:web:ebeaddf181db4379540aed",
  measurementId: "G-YB4SXLGWM0"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyDs9BYld2dQa_W37FJzvC0pY46JtaMBHFI",
//   authDomain: "hana-tours-ced96.firebaseapp.com",
//   projectId: "hana-tours-ced96",
//   storageBucket: "hana-tours-ced96.appspot.com",
//   messagingSenderId: "870777065328",
//   appId: "1:870777065328:web:64523bd7bfb12dad1d89b5"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBzI-R2cW1ghmBiE-_GjE-Gs4DVHeTUfU4",
  authDomain: "hana-tours-dacbc.firebaseapp.com",
  projectId: "hana-tours-dacbc",
  storageBucket: "hana-tours-dacbc.appspot.com",
  messagingSenderId: "631011937944",
  appId: "1:631011937944:web:ebeaddf181db4379540aed",
  measurementId: "G-YB4SXLGWM0"
};



  // init firebase
  const app = initializeApp(firebaseConfig)
  const app2 = initializeApp(firebaseConfigAmna, 'amna')

  //init firestore
  const db = getFirestore(app)
  const db2 = getFirestore(app2)

  //init auth
  const auth = getAuth(app)

  //init storage
  const storage = getStorage(app)

  //timestamp
  const timestamp =  Timestamp


  export { db, auth, storage, db2, timestamp }