import './Card.scss'
import locationIcon from '../assets/location-icon.png'
import PrimaryBtn from './PrimaryBtn'
import { useNavigate } from 'react-router-dom';


function Card({isTransfer, cardDetails, toggleFormOpen}) {
  const navigate = useNavigate()
  return (
    
     <div className='card'  style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0.13%, rgba(0, 0, 0, 0.3) 74.94%), url(${cardDetails.backgroundPhoto})`}}>
      <div className="navigate-purpose" onClick={() => cardDetails.id ? navigate(`/tours/${cardDetails.id}`) : ''}>

      </div>
        <h5 className="card-title" >{cardDetails.title}</h5>
        { cardDetails.location && <div className="card-location"> <img src={locationIcon} alt="location icon" />  <p>{cardDetails.location}</p> </div>}
        <div onClick={ () => toggleFormOpen()}>
        <PrimaryBtn btnText={`${isTransfer ? 'Send Inquiry': 'Book Now'} `}  />

        </div>
    </div>
  )
}

export default Card