import { useEffect, useRef } from 'react'

import Header from "../../components/Header"
import LandingSection from "../../components/LandingSection"

function Home() {
  let showButton = true;
  const myRef = useRef(null)

  
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])
   
 
  return (
    <div className="home">
      <div></div>
      <Header
       showButton={showButton}
       headingText={'Mostar Landmarks Tours'}
       btnText={'Explore'} 
       myRef={myRef}
      />
      <div ref={myRef}>
        <LandingSection/>
      </div>

      {/* Dodati mapu i contact na kraj to tek ne moras odma znas */}
    </div>
  )
}

export default Home