import { createUserWithEmailAndPassword } from 'firebase/auth'
import { collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryBtn from '../../components/PrimaryBtn'
import { auth, db } from '../../firebase/config'
import './Cms.scss'

function Cms() {
  const [tours, setTours] = useState()
  const navigate = useNavigate()  
  const { id } = useParams()
  const [deleteModal, setDeleteModal] = useState(false)
  const [tourToBeDeleted, setTourToBeDeleted] = useState('')
  const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('mostar123')


  const getTours = async() => {
    let ref = collection(db, 'cards')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setTours(results)
      return () => unsub()
    })
  }

  useEffect(() => {
    getTours()
  
  }, [])

  const addUser = async (e) => {
    e.preventDefault()
    await setDoc(doc(db, "users", email), {
      email: email
    })
    window.location.reload()
    setEmail('')
    
  }


  const deleteConfirmation = (id) => {
    setDeleteModal(true)
    // console.log(id)

    setTourToBeDeleted(id)

  }

  const no = () => {
    setDeleteModal(false)
    setTourToBeDeleted('')

  }

  const deleteTour = async (id) => {
    // console.log(id)

    await deleteDoc(doc(db, "tours", id));
    await deleteDoc(doc(db, "cards", id));

    setDeleteModal(false)
  }

  return (
    <div className="cms">
        <div className={`delete-confirmation ${deleteModal ? 'active' : ''}`}>
          <p>Da li ste sigurni da zelite da obrisete turu?</p>
          <div>
            <button onClick={() => deleteTour(tourToBeDeleted)} >Da</button>
            <button onClick={no}>Ne</button>
          </div>
        </div>
        <h3>Sve ture <span></span></h3>
        <div className="cms-tours">
          { tours && tours.map((tour,index) => (
              <div key={tour.id} className="cms-tours-tour" >
               <div className="cms-tours-tour-index"><p>{index + 1}</p></div>
               <div className="cms-tours-tour-name" onClick={() => navigate(`/cms/edit-tour/${tour.id}`)}><p>{tour.name}</p></div>
               <div onClick={() => deleteConfirmation(tour.id)} className="cms-tours-tour-delete">
                <span className="material-symbols-outlined cms-edit-images-image-delete ">delete</span>
               </div>
             </div>
          )) 
         }
          <div className="cms-tours-btn-container" onClick={() => navigate('/cms/add-tour')}>
            <PrimaryBtn btnText={"Dodaj turu"} ></PrimaryBtn>
          </div>
        </div>

        <div className="cms-users">
          <h2 style={{marginTop: '80px', marginBottom: '20px'}}>Dodavanje korisnika</h2>

         <form onSubmit={addUser}>
          <label>Email korisnika:</label>
          <input value={email} type="email" onChange={e => setEmail(e.target.value)}/>
          <button type="submit">
            <PrimaryBtn btnText={'Dodaj'}></PrimaryBtn>
          </button>
         </form>


        </div>
    </div>
  )
}

export default Cms