import './Footer.scss'
import Logo from '../assets/logo.png'
import FacebookIcon from '../assets/facebook-icon.png'
import InstaIcon from '../assets/insta-icon.png'
import TripadvisorIcon from '../assets/tripadvisor-icon.png'
import CopyRightIcon from '../assets/cp-icon.png'
import { Link } from 'react-router-dom'

function Footer() {
  return (
      <footer className="footer">
          <div className="footer-nav">
              <Link to={'/'}>
                  <div className="logo">
                      <img src={Logo} alt="" />
                  </div>
              </Link>
              <div className="footer-nav-section">
                  <h4>Navigation</h4>
                  <ul>
                      <li><Link to={'/'}>Home</Link></li>
                      <li><Link to={'/tours'}>Tours</Link></li>
                      <li><Link to={'/contact'}>Contact</Link></li>
                      <li><Link to={'/about-us'}>About us</Link></li>
                  </ul>
              </div>
              <div className="footer-nav-section">
                  <h4>Social</h4>
                  <ul>
                      <li>
                          <Link to={'https://www.instagram.com/mostar_landmarks_tours/?hl=en'} target={'_blank'}>
                              <img src={InstaIcon}alt="Instagram icon" />
                              <p>Instagram</p>
                          </Link>
                      </li>
                      
                  
                  </ul>
              </div>
          </div>
          <hr />
          <div className="footer-copyright">
            <img src={CopyRightIcon} alt="Copy Right Icon" />
            <p>2024 Mostar Landmarks Tours. All rights reserved.</p>
          </div>
      </footer>
  )
}

export default Footer