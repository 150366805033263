import PrimaryBtn from '../../components/PrimaryBtn'
import './AddReview.scss' 
import StarIcon from '../../assets/StarIconAdd.png'
import HalfStarIcon from '../../assets/HalfStarIconAdd.png'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useAuthContext } from '../../firebase 9/useAuthContext'

function AddReview() {
    const [selectedRating, setSelectedRating] = useState(null)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    const [comment, setComment] = useState('')
    const {user} = useAuthContext()
    const [review, setReview] = useState(null)
    const [isEditing, setIsEditing] = useState(false)

  const getReview = async() => {
   
    const docRef = doc(db, "reviews", user.email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        setComment(docSnap.data().body)
        setSelectedRating(docSnap.data().rating)
        setIsEditing(true)
    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
    }
  
    setReview(docSnap.data())
  }

    useEffect(() => {
        getReview()
    },[])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if(comment.length < 1) {
            setError('Please write comment')
            return
        }

        if(!selectedRating){
            setError('Please select rating')
            return
        }

        await setDoc(doc(db, "reviews", user.email  ), {
            name: user.displayName,
            email: user.email,
            body: comment,
            rating: selectedRating.toString()
          });

          navigate('/reviews')
          
    }

    const selectRating = (rating) => {
        setSelectedRating(rating)

    }
  return (
    <div className='add-review'>
        <form>
        {!isEditing && <h2>Add review</h2>}
        {isEditing && <h2>Edit your review</h2>}

            <label> Your comment about tour: </label>
            <textarea required value={comment} onChange={e=> setComment(e.target.value)}></textarea>
            <label> Your rating: </label>
            <div className="add-review-rating">
                <div onClick={() => selectRating(1)} className="add-review-rating-item"> 
                    <h6>1 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(1.5)}   className="add-review-rating-item"> 
                    <h6>1.5 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={HalfStarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(2)}  className="add-review-rating-item"> 
                    <h6>2 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(2.5)}  className="add-review-rating-item"> 
                    <h6>2.5 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={HalfStarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(3)}  className="add-review-rating-item"> 
                    <h6>3 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(3.5)}  className="add-review-rating-item"> 
                    <h6>3.5 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={HalfStarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(4)}  className="add-review-rating-item"> 
                    <h6>4 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(4.5)}  className="add-review-rating-item"> 
                    <h6>4.5 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={HalfStarIcon} alt="" />
                    </div>
                </div>
                <div onClick={() => selectRating(5)}  className="add-review-rating-item"> 
                    <h6>5 star:  </h6>  
                    <div className="add-review-rating-item-stars">
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                        <img src={StarIcon} alt="" />
                    </div>
                </div>
             
                
            </div>
            <h6 style={{marginTop:'20px'}}>Your rating: {selectedRating} <img src={StarIcon} alt="" /> </h6>
            {error && <p style={{marginTop: '20px'}}>{error}</p> }
            {!isEditing && <button onClick={handleSubmit} type="submit">
                <PrimaryBtn btnText={'Add review'}></PrimaryBtn>
            </button>}
           { isEditing && <button onClick={handleSubmit} type="submit">
                <PrimaryBtn btnText={'Edit review'}></PrimaryBtn>
            </button> }
        </form>
    </div>
  )
}

export default AddReview