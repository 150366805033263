import { collection, doc, onSnapshot } from "firebase/firestore"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryBtn from "../../components/PrimaryBtn"
import { useLogin } from "../../firebase 9/useLogin"
import { useLogout } from "../../firebase 9/useLogout"
import { db } from "../../firebase/config"


function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const {login, isPending} = useLogin()
    const {logout } = useLogout()
    const navigate = useNavigate()
    const [users, setUsers] = useState(null)
    const [activatedUsers, setActivatedUsers] = useState(null)


  const getUsers = async() => {
    let ref = collection(db, 'users')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setUsers(results)
      return () => unsub()
    })
  }


  const getActivatedUsers = async() => {
    let ref = collection(db, 'activatedUsers')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setActivatedUsers(results)
      return () => unsub()
    })
  }

  useEffect(() => {
    getUsers()
    getActivatedUsers()
  }, [])

  
// console.log(activatedUsers)


    const handleSubmit = (e) => {
        e.preventDefault()
        // if(email ===)
        login(email, password)
    }

  return (
    
    users && activatedUsers && <div className='login'>
            <form onSubmit={e => handleSubmit(e)} >

                <label>Email:</label>
                <input type="email" placeholder='Email' value={email} required onChange={e => setEmail(e.target.value)} />

                <label>Password:</label>
                <input type="password" placeholder='Password' value={password} required onChange={e => setPassword(e.target.value)} />


              {!isPending &&  <button type='submit'><PrimaryBtn btnText={'Login'}></PrimaryBtn></button>}
              {isPending &&  <button type='submit' disabled><PrimaryBtn btnText={'Loading...'}></PrimaryBtn></button>}

            </form>
        </div>
  )
}

export default Login