import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../assets/logo.png'
import { useAuthContext } from '../firebase 9/useAuthContext'
import { useLogout } from '../firebase 9/useLogout'
import './Navbar.scss'
import PrimaryBtn from './PrimaryBtn'
import PersonIcon from '../assets/PersonIcon.png'


function Navbar() {
  const { logout } = useLogout()
  const [open, SetOpen] = useState(false)
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    // console.log(location.pathname)
  
  }, [])

  const logoutFromPage = () => {
    logout()
    navigate('/')
  }
  

  return (
    <div className='navbar' style={{ borderBottom: location.pathname === '/reviews' ? '1px solid rgba(0,0,0,0.05)' : ''}} >
        <Link to={'/'}>
          <div className="logo">
            <img src={logo} alt="Mostar Landmarks and tours Logo" />
          </div>
        </Link>
        <ul className="navbar-links">
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/tours'}>Tours & Transfers</Link></li>
            <li><Link to={'/reviews'}>Reviews</Link></li>
            <li><Link to={'/contact'}>Contact</Link></li>
            <li><Link to={'/about-us'}>About us</Link></li>
              { user && user.email === 'amna@mostar.com' && <div className="logout" onClick={logout}>
                  <PrimaryBtn btnText={'Logout'}></PrimaryBtn>
              </div>}
              {  user && user.email === 'amna@mostar.com' && <div className='profile-btn' onClick={() => navigate('/cms')} > <img src={PersonIcon} alt="" /> Hello, Amna</div> }

              {  user && user.email !== 'amna@mostar.com' && <div className='profile-btn' onClick={() => navigate('/my-profile')} > <img src={PersonIcon} alt="" /> Hello, {user.displayName}</div> }
              { user && user.email !== 'amna@mostar.com' && <div className="logout" onClick={logoutFromPage}>
                  <PrimaryBtn btnText={'Logout'}></PrimaryBtn>
              </div>}
          
        </ul>

   
        <div onClick={() => SetOpen(!open)} className={`hamburger-menu ${open ? 'open' : ''}`}>
                  <div className="line line-1"></div>
                  <div className="line line-2"></div>
                  <div className="line line-3"></div>
            </div>

            <div className={`nav-links-mobile ${open ? 'active' : ''}`}>
              <ul>
                <li><Link onClick={() => SetOpen(!open)}  to={'/'}>Home</Link></li>
                <li><Link onClick={() => SetOpen(!open)}  to={'/tours'}>Tours</Link></li>
                <li><Link onClick={() => SetOpen(!open)}  to={'/contact'}>Contact</Link></li>
                <li><Link onClick={() => SetOpen(!open)}  to={'/reviews'}>Reviews</Link></li>
                { user && <div className="logout" onClick={logout}>
                  <PrimaryBtn btnText={'Logout'}></PrimaryBtn>
                </div>}
                { user && user.email === 'amna@mostar.com' && <div className="logout" onClick={() => navigate('/cms')}>
                  <button>Postavke</button>
              </div>}
              { user && <div  className="logout" onClick={() =>  navigate('/my-profile')}>
                  <button>My profile</button>
              </div>}
              </ul>
              
            </div>
            
    </div>
  )
}

export default Navbar