import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../firebase 9/useAuthContext'
import { db } from '../../firebase/config'
import './MyProfile.scss'
import StarIcon from '../../assets/StarIcon.png'
import HalfStarIcon from '../../assets/HalfStarIcon.png'
import PersonIcon from '../../assets/PersonIcon.png'
import PrimaryBtn from '../../components/PrimaryBtn'
import { useNavigate } from 'react-router-dom'

function MyProfile() {
    const {user} = useAuthContext()
    const [review, setReview] = useState(null)
    const [newRating, setNewRating] = useState('')
    const [includesHalf, setIncludesHalf] = useState(false)
    const navigate = useNavigate()

  const getReview = async() => {
   
    const docRef = doc(db, "reviews", user.email);
    const docSnap = await getDoc(docRef);
    setReview(docSnap.data())

    // if(docSnap.data().rating('.5')){
    //     console.log('ssd')
    // }

    if (docSnap.exists()){
        if(docSnap.data().rating.includes('.5') ) {
            let slicedRating = docSnap.data().rating.slice(0,1);
        
           setNewRating(slicedRating)
           setIncludesHalf(true)
    
        } else {
            setNewRating(docSnap.data().rating)
           setIncludesHalf(false)
    
        }
    }

   
  }



  useEffect(() => {
    getReview()
  
  }, [])

  // console.log(review)
  return (
    <div className='my-profile'>
        <h4> Hello, {user.displayName}  <span></span> </h4>


       { review && <h6>Your review:</h6>}
        {review &&  <div className="reviews-grid-item">
              <p className='reviews-grid-item-p'>{review.body}</p>
              <div className="reviews-grid-item-bottom">
                  <div className="reviews-grid-item-bottom-left">
                      <img src={PersonIcon} alt="" />
                  </div>
                  <div className="reviews-grid-item-bottom-right">
                      <div className="name"> <p>{review.name}</p> </div>
                      <div className="review">
                       
                          {
                            Array.apply(null, { length: newRating }).map((e, i) => (
                                <img src={StarIcon} key={i} alt="Rating Star" />
                              ))
                          }
                          { includesHalf && <img src={HalfStarIcon} /> }
                      </div>
                  </div>
              </div>
          </div>}

        {!review && 
            <div>   
                <h6>You have not added review yet!</h6>
                 <button onClick={() => navigate('/reviews/add-review')}>
                <PrimaryBtn btnText={'Add review'}></PrimaryBtn>
                </button>

            </div>
           
        }

    
    </div>
  )
}

export default MyProfile