import Header from '../../components/Header'
import Card from '../../components/Card'
import './Tours.scss'
import { useEffect, useRef, useState } from 'react';
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import emailjs from '@emailjs/browser'
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';



function Tours() {
  let showButton = false;
  const[isFormOpen, setIsFormOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [numberOfPeople, setNumberOfPeople] = useState('')
  const [date, setDate] = useState(new Date())
  const [cards, setCards] = useState([])
  const [transfers, setTransfers] = useState([])
  const [isPending, setIsPending] = useState(false)
  const [tourName, setTourName ] = useState('')
  const [confirmationMessageDisplay, setConfirmationMessageDisplay] = useState(false)
  const navigate = useNavigate()

  const form = useRef()

  const getCards = async() => {
    let ref = collection(db, 'cards')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setCards(results)
      return () => unsub()
    })
  }


  const getTransfers = async() => {
    let ref = collection(db, 'transfers')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setTransfers(results)
      return () => unsub()
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });

      getCards()
      getTransfers()
  }, [])
  



const options = [
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
]

const inputStyles = {control: styles => ({ ...styles, borderRadius: '10px',fontSize: '1.6rem' ,width: '100%', padding: '10px', border: '1px solid rgba(0,0,0,0.2)' })}


  const toggleFormOpen = (card) => {
    if(card) {
      setTourName(card.name)
    }
   
    setIsFormOpen(!isFormOpen)
    if(!isFormOpen) {
      window.scrollTo({ top: 0 });
    }
  }

  const sendBookRequest = async (e) => {
    e.preventDefault()
   setIsPending(true)

    await emailjs.sendForm('service_6k88pmf', 'template_jirznug', form.current,"DcJQ8SUIAhqGPjRYz")
    .then((result) => {
      setIsPending(false)
      setConfirmationMessageDisplay(true)

      setTimeout(() => {
        navigate(
          '/'
        )
      }, 2000)



    }, (error) => {
        console.log(error.text);
      setIsPending(false)
      setConfirmationMessageDisplay(false)
    });
    

    


  }


  return (
    <div className="tours">

      <div className={`confirmation-message ${confirmationMessageDisplay ? 'confirmation-message-active' : ''}`}>
        <div className="confirmation-message-main">
        <p>Request for Booking succesfull <span></span></p>

        </div>
      </div>

      <div className={`form ${isFormOpen ? 'active' : ''}`}>
          <div className="form-inside">
            <div className='close-btn' onClick={toggleFormOpen}>
              Close
            </div>
            <form ref={form} onSubmit={sendBookRequest}>
                <input  placeholder={tourName}/>
                <input required value={firstName} type="text" placeholder='First name' onChange={e=> setFirstName(e.target.value)} />
                <input required value={lastName} type="text" placeholder='Last name' onChange={e=> setLastName(e.target.value)}  />
                <input required value={email} type="email" placeholder='Email'  onChange={e=> setEmail(e.target.value)}  />
                <input required value={phoneNumber} type="phone" placeholder='Phone'  onChange={e=> setPhoneNumber(e.target.value)} />
                <Select  required  onChange={(n) => setNumberOfPeople(n.label)} placeholder='Number of people' options={options} styles={inputStyles} ></Select>
                <DatePicker required  wrapperClassName='date-picker' styles={{width: '100%'}} selected={date} minDate={date}> </DatePicker>
                <textarea placeholder='If you have any special requirements or questions...'></textarea>
                { !isPending && <button className='primary-btn' type='submit'>Send message</button>}
                { isPending && <button className='primary-btn' type='submit' disabled={true} >Loading...</button>}
            </form>
          </div>
        
      </div>
    

      <h2>Tours <span></span></h2>

      <div className="tours-cards">
        { cards && cards.map((card) => (
          <Card  key={card.id} toggleFormOpen={() => toggleFormOpen(card)} cardDetails={{title: card.name, location: card.location, backgroundPhoto: card.bgPhoto, id: card.id}}/>
        ))}
      </div>
        
      <h2>Transfers <span></span></h2>

      <div className="tours-cards">

        { transfers && transfers.map((card) => (
          <Card isTransfer={true}  key={card.id} toggleFormOpen={toggleFormOpen} cardDetails={{title: card.name, backgroundPhoto: card.bgPhoto}}/>
        ))}
      </div>

      <HelmetProvider>
        <Helmet>
          <title>Tours - Mostar Landmarks Tours</title>
          <meta name='description' content='Mostar Landmarks Private Tours'/>
          <link rel="canonical" href={`https://www.mostarlandmarkstours.com/tours`} />
        </Helmet>
      </HelmetProvider>
      
    </div>
  )
}

export default Tours