import { useEffect, useRef } from 'react'

import Header from "../../components/Header"
import LandingSection from "../../components/LandingSection"
import './About.scss'

function About() {
  const myRef = useRef(null)

  useEffect(() => {
    window.scrollTo({ top: 0 });

  }, [])

  
 
  return (
    <div className="about">
    
      <div ref={myRef}>
        <LandingSection/>
      </div>

      {/* Dodati mapu i contact na kraj to tek ne moras odma znas */}
    </div>
  )
}

export default About