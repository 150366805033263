import { addDoc, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryBtn from '../../../components/PrimaryBtn'
import { db, storage, timestamp } from '../../../firebase/config'
import './TourEdit.scss'
import imageCompression from 'browser-image-compression';

function TourEdit() {
  const { id } = useParams()
  const [tour, setTour] = useState(null)
  const [tourBackup, setTourBackup] = useState([])
  const [saving, setSaving] = useState(false)
  const [prices, setPrices] = useState([])
  const [included, setIncluded] = useState([])
  const [excluded, setExcluded] = useState([])
  const [paragraphsBeforeImages, setParagraphsBeforeImages] = useState([])
  const [paragraphsAfterImages, setParagraphsAfterImages] = useState([])
  const [notes, setNotes] = useState([])
  const [counter, setCounter] = useState(0)
  const [images, setImages] = useState([])
  const [imagesToSave, setImagesToSave] = useState([])
  const [imagesWithUpload, setImagesWithUpload] = useState([])
  const [error, setError] = useState('')
  const [areImagesSaved, setAreImagesSaved] = useState(true)
  const [isImageDeleted, setIsImageDeleted] = useState(false)
  const navigate = useNavigate()
  const [deletingProcess, setDeletingProcess] = useState(false)
  const [addingProcess, setAddingProcess] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [imageToBeDeleted, setImageToBeDeleted] = useState(null)
  const [tourCard, setTourCard] = useState(null)


  const getTour = async () => {
    const docRef = doc(db, "tours", id);
    const docSnap = await getDoc(docRef);

    const cardRef = doc(db, 'cards', id)
    const cardSnap = await getDoc(cardRef);

    if(cardSnap.exists()) {
      setTourCard(cardSnap.data())
    }


    if (docSnap.exists()) {
      let d = docSnap.data()
      setTour(d);
      setTourBackup(d)
      let imgs = []
      d.photos && d.photos.forEach((img, index) => {
        imgs.push({
          url: img.url,
          name: img.name,
          clicked: false,
          position: index + 1,
          decription: img.decription
        })
      })
      setImagesToSave(imgs)
      setImagesWithUpload(imgs)
      setImages(d.photos)
      setParagraphsBeforeImages(d.beforeImages)
      setParagraphsAfterImages(d.afterImages)
      setPrices(d.tourPrice)
      setIncluded(d.included)
      setExcluded(d.excluded)
      setNotes(d.notes)
      document.title = `${docSnap.data().name}`

    } else {
      console.log("No such document!");
    }
  }

  // console.log(tourCard)


  const year = new Date().getFullYear()
  const day = new Date().getDate()
  const month = new Date().getMonth() + 1
  const hours = new Date().getHours()
  const minutes = new Date().getMinutes()

  const dateOfChange = `${hours}:${minutes} ${day}-${month}-${year}`


  const saveTour = async () => {
    let real = []

    // if(isImageDeleted) {
    //   setError('Obrisali ste sliku, prvo sacuvajte slike')
    //   return
    // }

    if(areImagesSaved === false) {
      setError('Molim vas prvo sacuvajte slike')
      return
    }


    if (counter > 0 && counter !== imagesToSave.length) {
      setError('Niste izabrali sve slike')
      return
    } else {
      imagesToSave.sort(compare)
      imagesToSave.forEach((img) => {
        real.push({
          url: img.url,
          name: img.name,
          decription: img.decription
        })
      })
    }

    // console.log(imagesToSave)

    setError('')
    setCounter(0)

    setSaving(true)
    const tourRef = doc(db, 'tours', id)
    const cardRef = doc(db, 'cards', id)

    // Define the document reference
    const backupCollection = collection(db, 'backup');
    const myDocRef = doc(backupCollection, id);


    await setDoc(myDocRef, { ...tourBackup, changed: dateOfChange, Timestamp: dateOfChange });

    await updateDoc(cardRef, {
      name: tour.name,
      location: tour.location,
      bgPhoto: real[0].url
    })

    await updateDoc(tourRef, { ...tour, photos: real });
    // Log a success message
    setSaving(false)
    navigate(`/tours/${id}`)

  }


  const tourData = {
    name: 'Dubrovnik - Mostar',
    bgPhoto: ''
  }

  const addTour = async () => {
    const ref = collection(db, 'tours')
    const newDoc = await addDoc(ref, tourData)

    // Define the collection and document data
    const cardsCollection = collection(db, 'cards');
    const cardData = {
      name: tourData.name,
      location: tourData.location,
      bgPhoto: ''
    };

    // Define the document reference
    const myDocRef = doc(cardsCollection, newDoc.id);

    // Add or update the document
    await setDoc(myDocRef, cardData);
    // Log a success message

  }



  const addTransfer = async () => {
    const ref = collection(db, 'transfers')
    const newDoc = await addDoc(ref, tourData)
    // Define the collection and document data
    const transfersCollection = collection(db, 'transfers');
  }


  useEffect(() => {
    getTour()
  }, [])

  const handlePrices = (e, i) => {
    setPrices((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, tourPrice: newArr })
      return [...newArr]
    })
  }

  const addPrice = () => {
    setPrices((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, tourPrice: newArr })
      return newArr
    })
  }


  const deletePrice = (index) => {
    setPrices((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, tourPrice: newArr })
      return newArr
    })
  }


  const handleIncluded = (e, i) => {
    setIncluded((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, included: newArr })
      return newArr

    })
  }


  const addIncluded = () => {
    setIncluded((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, included: newArr })
      return newArr
    })
  }


  const deleteIncluded = (index) => {
    setIncluded((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, included: newArr })
      return newArr
    })
  }


  const handleExcluded = (e, i) => {
    setExcluded((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }



  const addExcluded = () => {
    setExcluded((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }


  const deleteExcluded = (index) => {
    setExcluded((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }




  const handleParagraphsBeforeImages = (e, i) => {
    setParagraphsBeforeImages((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }


  const handleParagraphsAfterImages = (e, i) => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }
  const addParagraphBefore = () => {
    setParagraphsBeforeImages((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }

  const addParagraphAfter = () => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }

  const deleteParagraphBefore = (index) => {
    setParagraphsBeforeImages((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }

  const deleteParagraphAfter = (index) => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }


  const handleNotes = (e, i) => {
    setNotes((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }

  const addNote = () => {
    setNotes((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }

  const deleteNote = (index) => {
    setNotes((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }


  function compare(a, b) {

    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }



  const handleClicked = (img, index) => {
    if(imagesToUpload.length > 0) {
      setError('Prvo sacuvajte slike pa im onda birajte poziciju')
      return
    }


    if (!img.clicked) {

      setCounter(prev => prev + 1);

      setImagesToSave((prevState) => {
        prevState[index].clicked = true
        prevState[index].position = counter + 1
        return [...prevState];
      });

    } else {

      if (img.position !== counter) {
        return
      }

      setCounter(prev => prev - 1);


      setImagesToSave((prevState) => {
        prevState[index].clicked = false
        prevState[index].position = index + 1


        return [...prevState];
      });
    }
  }

  function compare(a, b) {


    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  }


  // ADDIMAGES

  const fileInputRef = useRef()

  const [imagePrewiews, setImagePrewiews] = useState([])
  const [imagesToUpload, setImagesToUpload] = useState([])

  function handleFileChange(e) {
    const images = [...e.target.files]
    setImagesToUpload([...e.target.files])
    setAreImagesSaved(false)



    images.forEach((img, index) => {

      setImagesWithUpload(prev => {
        return [...prev, {
          url: URL.createObjectURL(img),
          name: img.name,
          clicked: false,
          position: imagesToSave.length + index + 1
        }]
      })

    })
  }


  const AddImage = async () => {
   
    if(imagesToUpload.length < 1 && !isImageDeleted) {
      setError('Niste odabrali nijednu sliku')
      return
    }

    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    let real = []
    const tourRef = doc(db, 'tours', id)

    setAddingProcess(true)

    imagesToUpload.forEach(async (img) => {

      const compressedFile = await imageCompression(img, options);


      const storageRef = ref(storage, `${id}/${img.name}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile, {
        contentType: img.type
      });

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':

              break;
          }
        },
        async () => {
          

          await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log('File available at', downloadURL);
              real.push({
                url: downloadURL,
                name: img.name,
                decription: ''
              })
              await updateDoc(tourRef, { ...tour, photos: [...tour.photos, ...real] });
              setImagesToSave((prev) => {
                return [...prev, {
                  name: img.name,
                  clicked: false,
                  url: downloadURL,
                  position: prev.length  + 1,
                  decription: ''

                }]
              })
           
          });
        }
      );

    })


    setAddingProcess(false)

      setAreImagesSaved(true)
      setError('')
      setIsImageDeleted(false)

      setImagesToUpload([])
      fileInputRef.current.value = ''

  }

  const deleteConfirmation = (image) => {
    if(counter > 0) {
      return
    }
    setDeleteModal(true)
    // console.log(id)

    setImageToBeDeleted(image)
  }

  const no = () => {
    setDeleteModal(false)
   setImageToBeDeleted(null)
  }

  const deleteTourImage = async (image) => {


    // console.log(image)
    deletePhoto(image)


    setDeleteModal(false)
  }

  const deletePhoto = async (image) => {
   
    setIsImageDeleted(true)
    setDeletingProcess(true)
    const tourRef = doc(db, 'tours', id)
    
    
    const imgRef = ref(storage, `${id}/${image.name}`);
    deleteObject(imgRef).then(async () => {
      await updateDoc(tourRef, { ...tour, photos: imagesToSave.filter((img) => {
        return img.name !== image.name
      }) });
      setImagesToSave(prev => prev.filter(img => {
        return image.name !== img.name
      }))

    if(tourCard === image.url) {
    const cardRef = doc(db, 'cards', id)

      await updateDoc(cardRef, {
        name: tour.name,
        location: tour.location,
        bgPhoto: imagesToSave[1].url
      })

    }

    setDeletingProcess(false)

   
    })
    .catch((error) => {
    });

   
    
      
  }

  function vidi(e, i) {
   setImagesToSave((prev) => {
    let newArr = [...prev]
    newArr[i].decription = e.target.value
    // console.log(newArr)
    return newArr
    
   })
  }



  return (
    tour && images && !saving && <div className="cms-edit">
        <div className={`delete-confirmation ${deleteModal ? 'active' : ''}`}>
          <p>Da li ste sigurni da zelite da obrisete sliku?</p>
          <div>
            <button onClick={() => deleteTourImage(imageToBeDeleted)} >Da</button>
            <button onClick={no}>Ne</button>
          </div>
        </div>
      <div className={`delete-modal ${deletingProcess ? 'active' : ''}`}>
        <p>Brisanje slike</p>
      </div>
      <div className={`adding-modal ${addingProcess ? 'active' : ''}`}>
        <p>Dodavanje</p>
      </div>
      <div className="cms-edit-section">
        <p >Tour name:</p>
        <input value={tour.name} type="text" onChange={e => setTour({ ...tour, name: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Location:</p>
        <input value={tour.location} type="text" onChange={e => setTour({ ...tour, location: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Price:</p>
        <div className="cms-edit-section-multi">
          {tour.tourPrice.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>

              <input value={prices[i]} type="text" onChange={e => handlePrices(e, i)} />
              <span onClick={() => deletePrice(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addPrice()}>
          <PrimaryBtn btnText={'Dodaj cijenu'}></PrimaryBtn>
        </div>
      </div>
      <div className="cms-edit-section">
        <p >Start time:</p>
        <input value={tour.startTime} type="text" onChange={e => setTour({ ...tour, startTime: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Duration:</p>
        <input value={tour.duration} type="text" onChange={e => setTour({ ...tour, duration: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Meeting point:</p>
        <input value={tour.meetingPoint} onChange={e => setTour({ ...tour, meetingPoint: e.target.value })} type="text" />
      </div>
      <div className="cms-edit-section">
        <p >Itinerary:</p>
        <input value={tour.itinerary} onChange={e => setTour({ ...tour, itinerary: e.target.value })} type="text" />
      </div>
      <div className="cms-edit-section">
        <p >Category:</p>
        <input value={tour.category} onChange={e => setTour({ ...tour, category: e.target.value })} type="text" />
      </div>

      <div className="cms-edit-section">
        <p >Difficulty:</p>
        <input value={tour.difficulty} onChange={e => setTour({ ...tour, difficulty: e.target.value })} type="text" />
      </div>

      <div className="cms-edit-section">
        <p >Main Note:</p>
        <input value={tour.noteMain} onChange={e => setTour({ ...tour, noteMain: e.target.value })} type="text" />
      </div>


      <div className="cms-edit-section">
        <p>Notes:</p>
        <div className="cms-edit-section-multi">
          {tour.notes.map((note, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <input value={notes[index]} type="text" onChange={e => handleNotes(e, index)} />
              <span onClick={() => deleteNote(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addNote()}>
          <PrimaryBtn btnText={'Dodaj notu'}></PrimaryBtn>
        </div>
      </div>


      <div className="cms-edit-section">
        <p >Included:</p>
        <div className="cms-edit-section-multi">
          {tour.included.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>
              <input value={included[i]} key={i} type="text" onChange={e => handleIncluded(e, i)} />
              <span onClick={() => deleteIncluded(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addIncluded()}>
          <PrimaryBtn btnText={'Dodaj stavku'}></PrimaryBtn>
        </div>
      </div>


      <div className="cms-edit-section">
        <p >Excluded:</p>
        <div className="cms-edit-section-multi">
          {tour.excluded.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>
              <input value={excluded[i]} key={i} type="text" onChange={e => handleExcluded(e, i)} />
              <span onClick={() => deleteExcluded(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addExcluded()}>
          <PrimaryBtn btnText={'Dodaj stavku'}></PrimaryBtn>
        </div>
      </div>

      <div className="cms-edit-section">
        <p>Paragraphs before images:</p>
        <div className="cms-edit-section-multi">
          {tour.beforeImages.map((p, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <textarea value={paragraphsBeforeImages[index]} type="text" onChange={e => handleParagraphsBeforeImages(e, index)} />
              <span onClick={() => deleteParagraphBefore(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addParagraphBefore()}>
          <PrimaryBtn btnText={'Dodaj paragraph'}></PrimaryBtn>
        </div>
      </div>

      <div className="cms-edit-section">
        <p>Paragraphs after images:</p>
        <div className="cms-edit-section-multi">
          {tour.afterImages.map((p, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <textarea className='par' value={paragraphsAfterImages[index]} type="text" onChange={e => handleParagraphsAfterImages(e, index)} />
              <span onClick={() => deleteParagraphAfter(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addParagraphAfter()}>
          <PrimaryBtn btnText={'Dodaj paragraph'}></PrimaryBtn>
        </div>
      </div>

      <h4 style={{margin: '0', marginTop: '40px', display: 'block'}}>Images:</h4>
      <div className="cms-edit-images">

        {imagesToUpload.length < 1 && imagesToSave.map((photo, i) => (
          <div key={i}  className="cms-edit-images-image">
            {photo.clicked && <div className="counter">{photo.position}</div>}
            <img onClick={() => handleClicked(photo, i)} src={photo.url} alt="" />
            <div className="cms-edit-images-image-bottom">
              <span onClick={() => deleteConfirmation(photo)} className="material-symbols-outlined cms-edit-images-image-delete ">delete</span>
              <div className='cms-edit-images-image-description' > 
                <label>Opis slike:</label>
                <input value={photo.decription} type="text" onChange={e => vidi(e, i) }/>
              </div>
            </div>
       

          </div>
        ))}
        {imagesToUpload.length > 0 && imagesWithUpload.map((photo, i) => (
                  <div key={i} onClick={() => handleClicked(photo, i)} className="cms-edit-images-image">
                    {photo.clicked && <div className="counter">{photo.position}</div>}
                    <img src={photo.url} alt="" />
                  </div>
          ))}
        {/* {imagePrewiews && imagePrewiews.map((imagePrewiew) => (
                <img style={{ height: '200px' }} src={imagePrewiew} />
            ))} */}
      </div>



     { counter < 1 && <input ref={fileInputRef} type="file" multiple onChange={handleFileChange} />}
      <div className="btn-wrap" onClick={() => AddImage()}>
        <PrimaryBtn btnText={'Sacuvaj slike'}></PrimaryBtn>
      </div>


      <p>{error}</p>

      <div className="btn-wrap" onClick={() => saveTour()}>
        <PrimaryBtn btnText={'Sacuvaj promjene'}></PrimaryBtn>
      </div>

    </div>
  )
}

export default TourEdit



