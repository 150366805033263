import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { hydrate, render } from 'react-dom';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
  
//     <AuthContextProvider>
//       <App />
//     </AuthContextProvider>
  
// );

// const pageUrl = 'https://mostarlandmarkstours.com/tours/aYj1FcR9YUDURak0oeGQ'

// const puppeteer = require('puppeteer/lib/api');
// (async () => {
//   // Create an instance of the chrome browser
//   // But disable headless mode !
//   const browser = await puppeteer.launch({
//       headless: false
//   });

//   // Create a new page
//   const page = await browser.newPage();

//   // Configure the navigation timeout
//   await page.setDefaultNavigationTimeout(0);

//   // Navigate to some website e.g Our Code World
// await page.goto(pageUrl, { waitUntil: "load" });



//   // Do your stuff
//   // ...
// })();


     
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(  
    <AuthContextProvider>
      <App />
    </AuthContextProvider>, rootElement);
} else {
  render(   
    <AuthContextProvider>
      <App />
    </AuthContextProvider>, rootElement);
}


// const getCards = async() => {
    
//   let ref = collection(db2, 'cards')
//   const unsub = onSnapshot(ref, (snapshot) => {
//     let results = []
//     snapshot.docs.forEach(doc => {
//       results.push({...doc.data(), id: doc.id})
//     })
//     setCards(results)
//     return () => unsub()
//   })

    
//   let ref2 = collection(db2, 'tours')
//   const unsub2 = onSnapshot(ref2, (snapshot) => {
//     let results = []
//     snapshot.docs.forEach(doc => {
//       results.push({...doc.data(), id: doc.id})
//     })
//     setTours(results)
//     return () => unsub2()
//   })

// }

// console.log(tours)
// Add a new document in collection "cities"
// const sendCards = async () => {



// tours.forEach(async (tour,index) => {
//   console.log(tour.id)
//   await setDoc(doc(db, "cards2", tour.id), {
    
//     bgPhoto: tours[index].photos[0],
//     name: tours[index].name,
//     location: tours[index].location
//   })
// })


// tours.forEach(async (tour,index) => {
//   console.log(tour)
// })



// cards.forEach(async (card,index) => {
  // console.log(tours[index])
  // await setDoc(doc(db, "cards2", tours[index].id), {

    // bgPhoto: tours[index].photos[0],
    // title: tours[index].name,
    // location: tours[index].location
  // }) ;
// })


// }

// useEffect(() => {
//   getCards()
// }, [])