import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore"
import { useRef, useState } from "react"
import PrimaryBtn from "../../../components/PrimaryBtn"
import { db, storage } from "../../../firebase/config"
import imageCompression from 'browser-image-compression';
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useNavigate } from "react-router-dom";

function TourAdd() {
  const [counter, setCounter] = useState(0)
  const [imagesToSave, setImagesToSave] = useState([])
  const [areImagesSaved, setAreImagesSaved] = useState(true)
  const [isImageDeleted, setIsImageDeleted] = useState(false)
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const [tour, setTour] = useState({
    name: '',
    location: '',
    tourPrice: [''],
    startTime: '',
    duration: '',
    meetingPoint: '',
    itinerary: '',
    category: '',
    difficulty: '',
    notes: [''],
    noteMain: 'Our tours are private tours for independent visitors and for pre-formed groups .We pick you up from your accommodation in Mostar at any time and the tour is fully flexible and customizable. The point of difference with our private tours is that you will get a unique experience and you will feel like a   local. Your needs and comfort are paramount to our success so rest assured there is no compromise on quality.',
    included: [''],
    excluded: [''],
    beforeImages: [''],
    afterImages: [''],
  })


  const [prices, setPrices] = useState(tour.tourPrice)

  const handlePrices = (e, i) => {
    setPrices((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, tourPrice: newArr })
      return [...newArr]
    })
  }

  const addPrice = () => {
    setPrices((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, tourPrice: newArr })
      return newArr
    })
  }


  const deletePrice = (index) => {
    setPrices((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, tourPrice: newArr })
      return newArr
    })
  }


  const[notes, setNotes] = useState(tour.notes)

  const handleNotes = (e, i) => {
    setNotes((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }

  const addNote = () => {
    setNotes((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }

  const deleteNote = (index) => {
    setNotes((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, notes: newArr })
      return newArr
    })
  }


  const [included, setIncluded] = useState(tour.included)

  const handleIncluded = (e, i) => {
    setIncluded((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, included: newArr })
      return newArr

    })
  }


  const addIncluded = () => {
    setIncluded((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, included: newArr })
      return newArr
    })
  }

  const deleteIncluded = (index) => {
    setIncluded((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, included: newArr })
      return newArr
    })
  }

  const [excluded, setExcluded] = useState(tour.excluded)
  const handleExcluded = (e, i) => {
    setExcluded((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }



  const addExcluded = () => {
    setExcluded((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }


  const deleteExcluded = (index) => {
    setExcluded((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, excluded: newArr })
      return newArr
    })
  }


  const handleParagraphsBeforeImages = (e, i) => {
    setParagraphsBeforeImages((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }


  const handleParagraphsAfterImages = (e, i) => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev]
      newArr[i] = e.target.value
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }

  const [paragraphsBeforeImages, setParagraphsBeforeImages] = useState(tour.beforeImages)
  const [paragraphsAfterImages, setParagraphsAfterImages] = useState(tour.afterImages)

  const addParagraphBefore = () => {
    setParagraphsBeforeImages((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }

  const addParagraphAfter = () => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev, '']
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }

  const deleteParagraphBefore = (index) => {
    setParagraphsBeforeImages((prev) => {
  let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, beforeImages: newArr })
      return newArr
    })
  }

  const deleteParagraphAfter = (index) => {
    setParagraphsAfterImages((prev) => {
      let newArr = [...prev.filter((p, i) => i !== index)]
      setTour({ ...tour, afterImages: newArr })
      return newArr
    })
  }





  const saveTour = async () => {
    const savedTour = await addDoc(collection(db, "tours"), tour);
    // setTourId(savedTour.id)
    await setDoc(doc(db, "cards", savedTour.id), {
      name: tour.name,
      bgPhoto: '',
      location: tour.location
    });
    await updateDoc(doc(db, "tours", savedTour.id), {
      id: savedTour.id
    });

    console.log(savedTour.id)
    AddImage(savedTour.id)
    navigate(`/tours`)
    
  }



  const fileInputRef = useRef()

  const [imagePrewiews, setImagePrewiews] = useState([])
  const [imagesToUpload, setImagesToUpload] = useState([])
  const [imagesWithUpload, setImagesWithUpload] = useState([])


  function handleFileChange(e) {
    const images = [...e.target.files]
    setImagesToUpload([...e.target.files])
    setAreImagesSaved(false)


    images.forEach((img, index) => {

      setImagesWithUpload(prev => {
        return [...prev, {
          url: URL.createObjectURL(img),
          name: img.name,
          clicked: false,
          position: imagesToSave.length + index + 1,
          decription: '',
        }]
      })

    })
  }

  function vidi(e, i) {
    // setImagesToSave((prev) => {
    //  let newArr = [...prev]
    //  newArr[i].decription = e.target.value
    //  console.log(newArr)
    //  return newArr
     
    // })
   }


  const AddImage = async (tourId) => {
   
    if(imagesToUpload.length < 1 && !isImageDeleted) {
   setError('Niste odabrali nijednu sliku')
      return
    }

    const options = {
      maxSizeMB: 0.6,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }

    let real = []
    const tourRef = doc(db, 'tours', tourId)

    imagesToUpload.forEach(async (img, index) => {

      const compressedFile = await imageCompression(img, options);
      const storageRef = ref(storage, `${tourId}/${img.name}`);
      const uploadTask = uploadBytesResumable(storageRef, compressedFile, {
        contentType: img.type
      });

      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
         break;
          }
        },
        (error) => {
          switch (error.code) {
            case 'storage/unauthorized':
              break;
            case 'storage/canceled':
              break;
            case 'storage/unknown':

              break;
          }
        },
        async () => {
          
    await getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            // console.log('File available at', downloadURL);
              real.push({
                url: downloadURL,
                name: img.name,
                decription: ''
              })
              await updateDoc(tourRef, { ...tour, photos: [...real] });
              if(index === 0 ) {
                await setDoc(doc(db, "cards", tourId), {
                  name: tour.name,
                  bgPhoto: downloadURL,
                  location: tour.location
                });
              }
              
          });
        }
      );

    })

      setAreImagesSaved(true)
      setError('')
      setIsImageDeleted(false)
      setImagesToUpload([])
      fileInputRef.current.value = ''
  }



  return (
    // tour && images && !saving && 
    <div className="cms-edit">
      <div className="cms-edit-section">
        <p >Tour name:</p>
        <input required value={tour.name} type="text" onChange={e => setTour({ ...tour, name: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Location:</p>
        <input value={tour.location} type="text" onChange={e => setTour({ ...tour, location: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Price:</p>
        <div className="cms-edit-section-multi">
          {tour.tourPrice.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>

              <input value={prices[i]} type="text" onChange={e => handlePrices(e, i)} />
              <span onClick={() => deletePrice(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addPrice()}>
          <PrimaryBtn btnText={'Dodaj cijenu'}></PrimaryBtn>
        </div>
      </div>
      <div className="cms-edit-section">
        <p >Start time:</p>
        <input value={tour.startTime} type="text" onChange={e => setTour({ ...tour, startTime: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Duration:</p>
        <input value={tour.duration} type="text" onChange={e => setTour({ ...tour, duration: e.target.value })} />
      </div>
      <div className="cms-edit-section">
        <p >Meeting point:</p>
        <input value={tour.meetingPoint} onChange={e => setTour({ ...tour, meetingPoint: e.target.value })} type="text" />
      </div>
      <div className="cms-edit-section">
        <p >Itinerary:</p>
        <input value={tour.itinerary} onChange={e => setTour({ ...tour, itinerary: e.target.value })} type="text" />
      </div>
      <div className="cms-edit-section">
        <p >Category:</p>
        <input value={tour.category} onChange={e => setTour({ ...tour, category: e.target.value })} type="text" />
      </div>

      <div className="cms-edit-section">
        <p >Difficulty:</p>
        <input value={tour.difficulty} onChange={e => setTour({ ...tour, difficulty: e.target.value })} type="text" />
      </div>

      <div className="cms-edit-section">
        <p >Main Note:</p>
        <input value={tour.noteMain} onChange={e => setTour({ ...tour, noteMain: e.target.value })} type="text" />
      </div>


      <div className="cms-edit-section">
        <p>Notes:</p>
        <div className="cms-edit-section-multi">
          {tour.notes.map((note, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <input value={notes[index]} type="text" onChange={e => handleNotes(e, index)} />
              <span onClick={() => deleteNote(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addNote()}>
          <PrimaryBtn btnText={'Dodaj notu'}></PrimaryBtn>
        </div>
      </div>


      <div className="cms-edit-section">
        <p >Included:</p>
        <div className="cms-edit-section-multi">
          {tour.included.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>
              <input value={included[i]} key={i} type="text" onChange={e => handleIncluded(e, i)} />
              <span onClick={() => deleteIncluded(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addIncluded()}>
          <PrimaryBtn btnText={'Dodaj stavku'}></PrimaryBtn>
        </div>
      </div>


      <div className="cms-edit-section">
        <p >Excluded:</p>
        <div className="cms-edit-section-multi">
          {tour.excluded.map((p, i) => (
            <div className="cms-edit-section-multi-inside" key={i}>
              <input value={excluded[i]} key={i} type="text" onChange={e => handleExcluded(e, i)} />
              <span onClick={() => deleteExcluded(i)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addExcluded()}>
          <PrimaryBtn btnText={'Dodaj stavku'}></PrimaryBtn>
        </div>
      </div>

      <div className="cms-edit-section">
        <p>Paragraphs before images:</p>
        <div className="cms-edit-section-multi">
          {tour.beforeImages.map((p, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <textarea value={paragraphsBeforeImages[index]} type="text" onChange={e => handleParagraphsBeforeImages(e, index)} />
              <span onClick={() => deleteParagraphBefore(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addParagraphBefore()}>
          <PrimaryBtn btnText={'Dodaj paragraph'}></PrimaryBtn>
        </div>
      </div>

      <div className="cms-edit-section">
        <p>Paragraphs after images:</p>
        <div className="cms-edit-section-multi">
          {tour.afterImages.map((p, index) => (
            <div className="cms-edit-section-multi-inside" key={index}>
              <textarea className='par' value={paragraphsAfterImages[index]} type="text" onChange={e => handleParagraphsAfterImages(e, index)} />
              <span onClick={() => deleteParagraphAfter(index)} className="material-symbols-outlined">delete</span>
            </div>
          ))}
        </div>
        <div onClick={() => addParagraphAfter()}>
          <PrimaryBtn btnText={'Dodaj paragraph'}></PrimaryBtn>
        </div>
      </div>

      <div className="cms-edit-images">
      <h4 style={{margin: '0', marginTop: '40px'}}>Images:</h4>


        {imagesToUpload.length < 1 && imagesToSave.map((photo, i) => (
          <div key={i}  className="cms-edit-images-image">
            {photo.clicked && <div className="counter">{photo.position}</div>}
            <img  src={photo.url} alt="" />
          </div>
        ))}
        {imagesToUpload.length > 0 && imagesWithUpload.map((photo, i) => (
                  <div className="cms-edit-images-image">
                    {photo.clicked && <div className="counter">{photo.position}</div>}
                    <img src={photo.url} alt="" />
                    <input value={photo.decription} type="text" onChange={e => vidi(e, i) }/>

                  </div>
          ))}
      </div>



     <input ref={fileInputRef} type="file" multiple onChange={handleFileChange} />
      {/* <div className="btn-wrap" onClick={() => AddImage()}>
        <PrimaryBtn btnText={'Sacuvaj slike'}></PrimaryBtn>
      </div> */}


      <p>{error}</p>

      <div className="btn-wrap" onClick={() => saveTour()}>
        <PrimaryBtn btnText={'Sacuvaj turu'}></PrimaryBtn>
      </div>

    </div>
  )
  }
  
  export default TourAdd