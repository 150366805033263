import { useNavigate } from 'react-router-dom'
import './Header.scss'
import PrimaryBtn from './PrimaryBtn'

function Header({showButton, btnText, headingText, paragraph, myRef}) {
  const navigate = useNavigate()

  return (
    <div className='header'>
        <div className="header-main">
            <h1>{ headingText }</h1>
            <p>Discover the magic of <span>Bosnia and Hercegovina</span>  </p>
            {showButton && 
              <div style={{marginTop: '20px'}} onClick={() => navigate('/tours')}>
                <PrimaryBtn btnText={btnText} />
              </div>
            
            }
        </div>
    </div>
  )
}

export default Header