import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { collection, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PrimaryBtn from '../../components/PrimaryBtn'
import { useLogin } from '../../firebase 9/useLogin'
import { useAuthContext } from '../../firebase 9/useAuthContext'
import { auth, db } from '../../firebase/config'
import './Login.scss'

function Signup() {
    const[email, setEmail] = useState('')
    const[userName, setUserName] = useState('')
    const[password, setPassword] = useState('')
    const [users, setUsers] = useState(null)
    const [userVerified, setIsUserVerified] = useState(false)
    const [vertificationDone, setVertificationDone] = useState(false)
    const [error, setError] = useState('Email not found, check again')
    const navigate = useNavigate()
    const { user } = useAuthContext()

    const handleSubmit = (e) => {
        e.preventDefault()
    // Provjera dli ga imad
        
        if ( users.filter((user) => {

            return user.email === email
        }).length < 1 ) {
            setVertificationDone(true)
            setIsUserVerified(false)
        } else {
            setVertificationDone(true)
            setIsUserVerified(true)

            createUserWithEmailAndPassword(auth, email, password)
            .then( async (userCredential) => {
              // Signed up 
              const user = userCredential.user;
               await updateProfile(auth.currentUser, { 
              displayName: userName
            })
              
              await setDoc(doc(db, "users", email), {
              email: email
              });

              await setDoc(doc(db, "activatedUsers", email), {
                email: email,
                displayName: userName,
                uid: user.uid
                });
              
                navigate('/')
                

              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log('asdas')
              // ..
            });
        }
    }

    useEffect(() => {
      getUsers()
    // console.log(user)

    }, [user])
    
    console.log(users)


  const getUsers = async() => {
    let ref = collection(db, 'users')
    const unsub = onSnapshot(ref, (snapshot) => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      })
      setUsers(results)
      return () => unsub()
    })
  }
    
    return (
       users && <div className='login'>
            <form onSubmit={e => handleSubmit(e)} >

                <label>Email:</label>
                <input type="email" placeholder='Email' value={email} required onChange={e => setEmail(e.target.value)} />

                <label>Your name:</label>
                <input type="text" placeholder='Name' value={userName} required onChange={e => setUserName(e.target.value)} />

                <label>Password:</label>
                <input type="password" placeholder='Password' value={password} required onChange={e => setPassword(e.target.value)} />


                <button type='submit'>
                    <PrimaryBtn btnText={'Sign up'}></PrimaryBtn>
                </button>
            { vertificationDone && !userVerified && <p>{error}</p> }

            </form>
        </div>
    )
}

export default Signup