import { BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './App.scss';
import Home from './pages/home/Home'
import Tours from './pages/tours/Tours'
import SingleTour from './pages/single-tour/SingleTour'
import Contact from './pages/contact/Contact'
import Cms from './pages/cms/Cms'
import TourEdit from './pages/cms/tour-edit/TourEdit'
import TourAdd from './pages/cms/tour-add/TourAdd'
import Navbar from './components/Navbar';
import PageNotFound from './components/PageNotFound';
import Footer from './components/Footer';
import Edit from './pages/Edit';
import { useAuthContext } from './firebase 9/useAuthContext';
import { db, db2 } from './firebase/config';
import Reviews from './pages/reviews/Reviews';
import Signup from './pages/login/Signup';
import AddReview from './pages/reviews/AddReview';
import { useEffect, useState } from 'react';
import Login from './pages/login/Login';
import MyProfile from './pages/my-profile/MyProfile';
import { HelmetProvider } from 'react-helmet-async';
import About from './pages/about/About';

function App() {
  const { authIsReady,user} = useAuthContext()
  const [whoIsUser, setWhoIsUser] = useState(null) 
  
  
  return (
    <div className="App">

     { authIsReady && setWhoIsUser && <BrowserRouter>
         <Navbar user={user}/> 
        <Routes>
        <Route path='*' element={<PageNotFound></PageNotFound>} />
          <Route path='/' element={<Home></Home>} />
          <Route path='/tours' element={<Tours  />}  />
          <Route path='/tours/:id' element={<SingleTour />}  /> 
          <Route path='/contact' element={<Contact></Contact>} />
          <Route path='/about-us' element={<About></About>} />
        
          { user && user.email !== 'amna@mostar.com' &&  <Route path='/cms' element={<Navigate to='/' />} />}
          { user &&  <Route path='/cms' element={<Cms></Cms>} />}
          { !user &&  <Route path='/cms' element={<Navigate to='/' />} />}


          { user && user.email !== 'amna@mostar.com' &&  <Route path='/cms/edit-tour/:id'  element={<Navigate to='/' />} />}
          { user && <Route path='/cms/edit-tour/:id' element={<TourEdit></TourEdit>} />}
          { !user && <Route path='/cms/edit-tour/:id' element={<Navigate to={'/'} />} />}

          { user && user.email !== 'amna@mostar.com' &&  <Route path='/cms/add-tour' element={<Navigate to='/' />} />}
          { user && <Route path='/cms/add-tour' element={<TourAdd></TourAdd>} />}
          { !user && <Route path='/cms/add-tour' element={<Navigate to={'/'}/>} />}

          { user && user.email !== 'amna@mostar.com' &&  <Route path='/edit' element={<Navigate to='/' />} />}
          { !user && <Route path='/edit' element={<Edit />} />}
          { user && <Route path='/edit' element={<Navigate  to='/' />} />}

          <Route path='/reviews' element={<Reviews />}/>

          {/* USTBARI SIGN UP  */}

        { user && user.email === 'amna@mostar.com' &&  <Route path='/login' element={<Navigate to='/' />} />}
         { !user && <Route path='/signup' element={<Signup />}/>}
         { user && <Route path='/signup' element={<Navigate to='/' />}/>}

         { user && user.email === 'amna@mostar.com' &&  <Route path='/reviews/add-review' element={<Navigate to='/' />} />}
         { user && <Route path='/reviews/add-review' element={<AddReview />} /> }
         { !user && <Route path='/reviews/add-review' element={<Navigate to='/login' />} /> }


         { user && user.email === 'amna@mostar.com' &&  <Route path='/login' element={<Navigate to='/' />} />}
         { !user && <Route path='/login' element={<Login />} /> }
         { user && <Route path='/login' element={<Navigate to='/' />} /> }

         { user && user.email === 'amna@mostar.com' &&  <Route path='/my-profile' element={<Navigate to='/' />} />}
         { user && <Route path='/my-profile' element={<MyProfile />} /> }
         { !user && <Route path='/my-profile' element={<Navigate to={'/login'} />} /> }

        </Routes>
        <Footer /> 
      </BrowserRouter>}

    
    </div>
    
  );
}

export default App;
