import { Helmet, HelmetProvider } from 'react-helmet-async'
import './Contact.scss'
import emailjs from '@emailjs/browser'
import { useState, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'


function Contact() {
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')
  // 
  const [isPending, setIsPending] = useState(false)
  const form = useRef()

  const [confirmationMessageDisplay, setConfirmationMessageDisplay] = useState(false)
  const navigate = useNavigate()

  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:hanatoursmostar@gmail.com';
}



const sendBookRequest = async (e) => {
  e.preventDefault()
  setIsPending(true)

  

  await emailjs.sendForm('service_6k88pmf', 'template_0s251fq', form.current,"DcJQ8SUIAhqGPjRYz")
  .then((result) => {
    setIsPending(false)
    setConfirmationMessageDisplay(true)

    setTimeout(() => {
      navigate(
        '/'
      )
    }, 2000)

  }, (error) => {
      console.log(error.text);
    setIsPending(false)
    setConfirmationMessageDisplay(false)


  });


  setIsPending(false)


}


  return (
    <div className="contact">

      <div className={`confirmation-message ${confirmationMessageDisplay ? 'confirmation-message-active' : ''}`}>
        <div className="confirmation-message-main">
        <p>Message sent<span></span></p>
        </div>
      </div>

      <div className="contact-landing">
        <div className="contact-landing-left">
          <div className="contact-landing-left-information">
            <div className="contact-landing-left-information-row">
              <h4>Phone</h4>
              <Link to="tel:0038762753591">+387 63 559 233</Link>
            </div>
            <div className="contact-landing-left-information-row">
              <h4>Email</h4>
              <Link
            to='#'
            onClick={(e) => handleClick(e)}
            >hanatoursmostar@gmail.com
        </Link>
            </div>
          </div>
        </div>
        
        <div className="contact-landing-right">
          <h2>Contact us</h2>
          <p>Get in touch, we will get back to you!</p>
          <form ref={form} onSubmit={sendBookRequest}>
                <input required value={userName} type="text" name="user_userName" placeholder='First name' onChange={e=> setUserName(e.target.value)} />
                <input required value={email} type="email" name="user_email" placeholder='Email'  onChange={e=> setEmail(e.target.value)}  />
                <input required value={phone} name="user_phone" type="phone" placeholder='Phone'  onChange={e=> setPhone(e.target.value)} />
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="user_message" placeholder='If you have any special requirements or questions...'></textarea>
                { !isPending && <button className='primary-btn' type='submit'>Send message</button>}
                { isPending && <button className='primary-btn' type='submit' disabled={true} >Loading...</button>}
            </form>
        </div>
      </div>
      <HelmetProvider>
        <Helmet>
          <title>Contact</title>
          <meta name='description' content='Contact us - Mostar Landmarks Private Tours'/>
          <link rel="canonical" href={`https://www.mostarlandmarkstours.com/contact`} />
        </Helmet>
      </HelmetProvider>
    </div>
  )
}

export default Contact