import { useState } from 'react'
import PrimaryBtn from '../components/PrimaryBtn'
import './Edit.scss' 
import {useLogin} from '../firebase 9/useLogin'

function Edit() {
    const { login, isPending } = useLogin()
    const[email, setEmail] = useState('')
    const[password, setPassword] = useState('')
    const handleSubmit = (e) => {
        e.preventDefault()
        // console.log(email, password)
        login(email,password)
    }
  return (
    <div className='login'>
        <form onSubmit={e => handleSubmit(e)} >
            <label>Email:</label>
            <input type="email"  placeholder='Email' value={email} required onChange={e => setEmail(e.target.value)}  />

            <label>Password:</label>
            <input type="password"  placeholder='Password' value={password} required onChange={e => setPassword(e.target.value)}  />
            {!isPending &&  <button type='submit'><PrimaryBtn btnText={'Login'}></PrimaryBtn></button>}
              {isPending &&  <button type='submit' disabled><PrimaryBtn btnText={'Loading...'}></PrimaryBtn></button>}
        </form>
    </div>
  )
}

export default Edit