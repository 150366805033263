import './Reviews.scss'
import QuotesIcon from '../../assets/QuotesIcon.png'
import PersonIcon from '../../assets/PersonIcon.png'
import StarIcon from '../../assets/StarIcon.png'
import HalfStarIcon from '../../assets/HalfStarIcon.png'
import PrimaryBtn from '../../components/PrimaryBtn'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { db } from '../../firebase/config'
import { collection, onSnapshot } from 'firebase/firestore'
import { useAuthContext } from '../../firebase 9/useAuthContext'
import { Helmet, HelmetProvider } from 'react-helmet-async'

function Reviews() {
    const navigate = useNavigate()
    const [reviews, setReviews] = useState(null)
    const { user } = useAuthContext()


    const getReviews = async() => {
        let ref = collection(db, 'reviews')
        const unsub = onSnapshot(ref, (snapshot) => {
          let results = []
          snapshot.docs.forEach(doc => {
            results.push({...doc.data()})
          })
          setReviews(results)
          return () => unsub()
        })
      }


      useEffect(() => {
        getReviews()
      }, [])

    const getRating = (review) => {

        if (review.rating.includes('.5')) {
            let slicedRating = review.rating.slice(0, 1);

            // setNewRating(slicedRating)
            // setIncludesHalf(true)

        } else {
            // setNewRating(review.rating)
            // setIncludesHalf(false)

        }
    }

      // console.log( (reviews && reviews.filter((review) => review.email === user.email) ))
  return (
    reviews && <div className='reviews'>
        <h1>Mostar Landmarks Tours</h1>
        <p>What people say <span></span> </p>
       { user && (reviews.filter((review) => review.email === user.email).length <1) && user.email !== 'amna@mostar.com' && <div className='btn-wrapp' onClick={() => navigate('/reviews/add-review')}>
            <PrimaryBtn btnText={'Add review'}></PrimaryBtn>
        </div>}
        { user && (reviews.filter((review) => review.email === user.email).length > 0) && <div className='btn-wrapp' onClick={() => navigate('/reviews/add-review')}>
            <PrimaryBtn btnText={'Edit review'}></PrimaryBtn>
        </div>}
        <div className="reviews-grid">
            <h3 className="fora">Testemonials</h3>
            { reviews.map((review) => (
                  <div className="reviews-grid-item" key={review.email}>
                  <p className='reviews-grid-item-p'>{review.body}</p>
                  <div className="reviews-grid-item-bottom">
                      <div className="reviews-grid-item-bottom-left">
                          <img src={PersonIcon} alt="" />
                      </div>
                      <div className="reviews-grid-item-bottom-right">
                          <div className="name"> <p>{review.name}</p> </div>
                          <div className="review">
                              {/* KASNIJE LOOP KROZ BROJ ZVJEZDICA 10 POLOVINA I PRAZNE NARAVNO KAD SE OCJENJUJE*/}

                             
                          {
                            Array.apply(null, { length: review.rating.slice(0,1) }).map((e, i) => (
                                <img src={StarIcon} key={i} alt="Rating Star" />
                              ))
                          }
                             { review.rating.includes('.5') && <img src={HalfStarIcon} /> }
                          </div>
                      </div>
                  </div>
              </div>
            ))}
          
            
        </div>
        <HelmetProvider>
        <Helmet>
          <title>Reviews</title>
          <meta name='description' content='Rewiews - Mostar Landmarks Private Tours'/>
          <link rel="canonical" href={`https://www.mostarlandmarkstours.com/reviews`} />
        </Helmet>
      </HelmetProvider>
    </div>
  )
}

export default Reviews