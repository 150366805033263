import './PageNotFound.scss'
import { useNavigate } from 'react-router-dom'
import PrimaryBtn from './PrimaryBtn'

function PageNotFound() {
    const navigate = useNavigate()
  return (
    <div className='page-not-found'>
        <h1>Page not found</h1>
        <button onClick={() => navigate('/')}>
            <PrimaryBtn btnText={'Go back to the home page'}></PrimaryBtn>
        </button>
    </div>
  )
}

export default PageNotFound