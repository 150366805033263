import './LandingSection.scss' 
import sectionImage from '../assets/section-image.png'
import map from '../assets/real-map.png'
import PrimaryBtn from './PrimaryBtn'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

function LandingSection() {
    const location = useLocation()
    const navigate = useNavigate()

    const handleClick = (e) => {
        e.preventDefault();
        window.location.href = 'mailto:hanatoursmostar@gmail.com';
    }

  return (
      <>
              <div className="landing-section landing-section-reverse landing-section--gray">
                {location.pathname === '/' &&  <div className="landing-section-image landing-section-section">
                      <img src={sectionImage} alt="" />
                  </div>}
                  <div className="landing-section-section" style={{flexBasis: location.pathname === '/' ? '50%' : '100%' }}>
                      <h3>About us <span></span></h3>
                      <p>

                          Welcome to Bosnia and Herzegovina! I understand that many of you may not have a clear picture of what country has to offer. My name is Amna, a certified local guide from Mostar. Having lived abroad in the USA and Belgium,I returned to my hometown a few years ago.Alongside a group of colleagues who are also natives of the area, we have made it our mission to showcase the wonders of our country. We understand that many people only associate Bosnia with stories of war, but we believe that the best way to change this perception is through our immersive tours.
                      </p>
                     { location.pathname === '/' && <div onClick={() => navigate('/about-us')} className="button-wrapp">
                        <PrimaryBtn btnText={'See more'}></PrimaryBtn>
                      </div>}
                  </div>
              </div>
{ location.pathname === '/about-us' &&
             <> <div className="landing-section">
                  <div className="landing-section-section">
                      <h4>Unforgettable memories in this <br /> small yet breathtaking country. <span></span></h4>
                  </div>

                  <div className="landing-section-section">
                      <p> <span className="bolded">Our goal</span>  Our is to invite you to witness the beauty, experience the culture, and create unforgettable memories in this small yet breathtaking country. Bosnia and Herzegovina boasts stunning mountains, untamed rivers, ancient monuments, delectable cuisine, and exquisite wines. Drawing from my own travel experiences, I can confidently say that the most authentic and genuine insights come from locals. That is why we extend a warm invitation for you to come and explore our homeland. I assure you, you won’t be disappointed. </p>
                  </div>
              </div>


              <div className="landing-section landing-section--gray landing-section--reverse">
                  <div className="landing-section-section">
                      <h4>Showcasing diversity, tradition, <br /> and Bosnian cultural heritage <span></span></h4>
                  </div>

                  <div className="landing-section-section">
                      <p> <span className="bolded">Our true passion</span>  lies in showcasing the diversity, traditions, and cultural heritage of Bosnia. Whether you prefer private tours or tailor-made experiences, we have you covered. History buffs, adventure seekers, wine enthusiasts, pilgrims, or those looking for a combination of different experiences - we cater to all interests. </p>
                  </div>
              </div>


              <div className="landing-section">
                  <div className="landing-section-section">
                      <h4>Proffessional service <br /> and joyful memories <span></span></h4>
                  </div>

                  <div className="landing-section-section">
                      <p> Whether you are traveling solo or with a group, our utmost priority is to provide you with <span className="bolded"> professional service</span> and ensure that you leave with joyful memories of everything you have encountered. If you are planning a short visit to Mostar and are staying in Split, Dubrovnik, Sarajevo, or any nearby city, we can arrange convenient transfers for you. Just give us a heads up in advance, and we will take care of all the logistics.  </p>
                  </div>
              </div>

              <div className="fade fade--reverse"></div>
              <div className="landing-section landing-section--gray landing-section-last">
                <p>
                Come and discover the hidden gems of Bosnia and Herzegovina with us. We can’t wait to welcome you and show you the true essence of our remarkable country. Our aim is quality and to design the tour and itinerary unique to each traveler, offering an authentic and more intimate experience.
                </p>
              </div>
              <div className="fade"></div></>
}

              <div className="map-and-contact">
                <div className="contact">
                    <div className="">
                        <h3>Contact us</h3>
                        <p>  <span></span> Get in touch, we will get back to you!</p>
                    </div>
                    <div className="info">
                        <div className="phone">
                            <h4>Phone</h4>
                            <Link to="tel:0038762753591">+387 63 559 233</Link>

                        </div>
                        <div className="email">
                            <h4>Email</h4>
                            <Link
            to='#'
            onClick={(e) => handleClick(e)}
            >hanatoursmostar@gmail.com
        </Link>
                          
                        </div>
                    </div>
                </div>
                <div className="map">
                        <img src={map} alt="Map of Balkan countries showcasting the biggest and most popular cities" />
                </div>
              </div>
        </>

  )
}

export default LandingSection