import './SingleTour.scss'
import PrimaryBtn from '../../components/PrimaryBtn'
import logoWhite from '../../assets/logo-white.png'
import yesIcon from '../../assets/yes-icon.png'
import noIcon from '../../assets/no-icon.png'
import galleryIcon from '../../assets/GalleryIcon.png'
import ImageGallery from "react-image-gallery";
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import emailjs from '@emailjs/browser'
import BlagajVideo from '../../assets/BlagajVideo.mp4'
import { collection, doc, getDocs, query, where,getDoc, onSnapshot, addDoc, setDoc } from "firebase/firestore";
import { db } from '../../firebase/config'
import { HelmetProvider, Helmet } from 'react-helmet-async'





function SingleTour({ tours  }) {
const { id } = useParams()
const [tour, setTour] = useState(null)
const [images, setImages] = useState([])
const [tourName,setTourName] = useState('')
const [isItWineTour, setIsItWineTour] = useState(false)
const [isItBlagajTour, setIsItBlagajisItBlagajTour] = useState(false)


const getTour = async() => {
  const docRef = doc(db, "tours", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {

    let imgs = []
    docSnap.data().photos.forEach((img,index) => {
      imgs.push({
        original: img.url,
        thumbnail: img.url
      })
    })
    setImages(imgs)


    setTour(docSnap.data());
    if(docSnap.data().name.includes('wine')){
      setIsItWineTour(true)
    }
    if(docSnap.data().name.includes('Blagaj') && docSnap.data().name.includes('Old')  && docSnap.data().name.includes('Town')){
      setIsItBlagajisItBlagajTour(true)
    }
    setTourName(docSnap.data().name)
    // document.title = `${docSnap.data().name}`
    

  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
  
}

useEffect(() => {
  getTour()
  window.scrollTo(0,0)

}, [])


const infoRef = useRef(null)
const photosRef = useRef(null)

// PRO FORME

let showButton = false;
  const[isFormOpen, setIsFormOpen] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [numberOfPeople, setNumberOfPeople] = useState('')
  const [date, setDate] = useState(new Date())
  const [message, setMessage] = useState('')
  const [isPending, setIsPending] = useState(false)
  const [confirmationMessageDisplay, setConfirmationMessageDisplay] = useState(false)
  const navigate = useNavigate()


  const form = useRef()

  const options = [
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
  ]

  const inputStyles = {control: styles => ({ ...styles, borderRadius: '10px',fontSize: '1.6rem' ,width: '100%', padding: '10px', border: '1px solid rgba(0,0,0,0.2)' })}


  const toggleFormOpen = () => {
    setIsFormOpen(!isFormOpen)
    if(!isFormOpen) {
      window.scrollTo({ top: 0 });
    }
  }

  const sendBookRequest = async (e) => {
    e.preventDefault()
    setIsPending(true)

    await emailjs.sendForm('service_6k88pmf', 'template_jirznug', form.current, 'DcJQ8SUIAhqGPjRYz')
    .then((result) => {
      setIsPending(false)
      setConfirmationMessageDisplay(true)

      setTimeout(() => {
        navigate(
          '/'
        )
      }, 2000)

    }, (error) => {
        console.log(error.text);
        setIsPending(false)
        setConfirmationMessageDisplay(false)
    });
    setIsPending(false)

  }




 



  return (
    tour && <div className="single-tour">
        <HelmetProvider>
      <Helmet>
        <title>{tour.name} private tour</title>
        <meta name='description' content={ `${tour.name} private tours ${tour.beforeImages.slice(0,100)}`}/>
        {/* <link rel="canonical" href={`https://www.mostarlandmarkstours.com/tours/${tour.id}`} /> */}
      </Helmet>
      </HelmetProvider>

      <div className={`confirmation-message ${confirmationMessageDisplay ? 'confirmation-message-active' : ''}`}>
        <div className="confirmation-message-main">
        <p>Request for Booking succesfull <span></span></p>

        </div>
      </div>
      
      {/* FORM */}
      <div className={`form ${isFormOpen ? 'active' : ''}`}>
          <div className="form-inside">
            <div className='close-btn' onClick={toggleFormOpen}>
              Close
            </div>
            <form ref={form} onSubmit={sendBookRequest}>
               <input value={tourName} type="text"  name='user_tourName'  onChange={e => setTourName(tourName)}/>
                <input required value={firstName} type="text" name="user_firstName" placeholder='First name' onChange={e=> setFirstName(e.target.value)} />
                <input required value={lastName} type="text" name="user_lastName"  placeholder='Last name' onChange={e=> setLastName(e.target.value)}  />
                <input required value={email} type="email" name="user_email" placeholder='Email'  onChange={e=> setEmail(e.target.value)}  />
                <input required value={phone} name="user_phone" type="phone" placeholder='Phone'  onChange={e=> setPhone(e.target.value)} />
                <Select name='user_numberOfPeople'  required  onChange={(n) => setNumberOfPeople(n.label)} placeholder='Number of people' options={options} styles={inputStyles} ></Select>
                <DatePicker required name="user_date"   wrapperClassName='date-picker' styles={{width: '100%'}} selected={date} minDate={date}> </DatePicker>
                <textarea value={message} onChange={(e) => setMessage(e.target.value)} name="user_message" placeholder='If you have any special requirements or questions...'></textarea>
                { !isPending && <button className='primary-btn' type='submit'>Book</button>}
                { isPending && <button className='primary-btn' type='submit' disabled={true} >Loading...</button>}
            </form>
          </div>
        </div>

      <div className="single-tour-header">

        <div className="single-tour-header-images">
          <div className={`single-tour-header-images-image ${isItWineTour ? 'wine-tour' : ''}`}>
            { tour.photos[0] && tour.photos[0].url && <img src={tour.photos[0].url} alt="" />}
          </div>
        </div>

        <div className="single-tour-header-cta">
          <div className="single-tour-header-cta-left">
            <ul>
              <li className='active'>About</li>
              <li onClick={() => infoRef.current.scrollIntoView({behavior: "smooth"})} >Tour information</li>
              <li onClick={() => photosRef.current.scrollIntoView({behavior: "smooth"})} >Photos</li>
            </ul>
          </div>
          <div style={{ margin: '0px 0px' }} onClick={toggleFormOpen}>
            <PrimaryBtn btnText={'Book now'} />
          </div>
        </div>
      </div>

      <div className="single-tour-main">
        <div className="single-tour-main-title">
          <h2> {tour.name} <span className='private-tour'> Private tour <span className='linea'></span></span> </h2>
        </div>
          <div className="single-tour-main-section">
            { tour.beforeImages.map((p, index) => (
              <p key={index}>  {p} </p>
            )) }
            <div className="single-tour-main-section-two-images">
              <div className="wrapper" >
                { tour.photos[1] && tour.photos[1].url && <img src={tour.photos[1].url} alt="" />}
                {/* <span className='image-description'>Old Bridge</span> */}
              </div>
              <div className="wrapper  mobile-none" >
                { tour.photos[2] &&  tour.photos[2].url && <img src={tour.photos[2].url} alt="" />}
                {/* <span className='image-description'>Mostar Old Town</span> */}
              </div>
            </div>
            { tour.afterImages.map((p, index) => (
              <p key={index}>  {p} </p>
            )) }
            <div className="single-tour-main-section-three-images">
              <div className="wrapper">
              { tour.photos[3] && tour.photos[3].url && <img src={tour.photos[3].url} alt="" />}
                {/* <span className='image-description'>Mostar Old Town</span> */}
              </div>
              <div className="wrapper">
               { tour.photos[4] &&  tour.photos[4].url && <img src={tour.photos[4].url}  alt="" />}
                {/* <span className='image-description'>Mostar Old Town</span> */}
              </div>
              <div className="wrapper mobile-none">
              { tour.photos[5] &&  tour.photos[5].url && <img src={tour.photos[5].url}  alt="" />}
                {/* <span className='image-description'>Mostar Old Town</span> */}
                {/* DODATI desc sa slike oridjidji hejeje */}
              </div>
            </div>
          </div>


        { isItBlagajTour &&  <div className="single-tour-main-section single-tour-main-section-video">
            <video  muted autoPlay src={BlagajVideo} type="video/mp4" />
          </div>}

          <hr />

          <div ref={infoRef} className="single-tour-information">
            <div className="fora">
              <h2>{tour.name}</h2>
              <div className="logo">
                <img src={logoWhite} alt="Amna Tours Logo" />
              </div>
            </div>

            <div className="single-tour-information-basic">
              <h6>Information about the tour <span></span> </h6>
              <div className="single-tour-information-basic-section">
                <h5>Tour Price:</h5>
                <ul>
                  { tour && tour.tourPrice.map(p => (
                    <li key={p}> <span></span> {p}</li>
                  )) }
                </ul>
              </div>

              <div className="single-tour-information-basic-section">
                <h5>Start time:</h5>
                <ul>
                  <li> <span></span> {tour.startTime}</li>
                </ul>
              </div>

              <div className="single-tour-information-basic-section">
                <h5>Tour duration:</h5>
                <ul>
                  <li> <span></span>{tour.duration}</li>
                </ul>
              </div>

              <div className="single-tour-information-basic-section">
                <h5>Difficulty level:</h5>
                <ul>
                  <li><span></span>{tour.difficulty}</li>
                </ul>
              </div>

              <div className="single-tour-information-basic-section">
                <h5>Meeting point:</h5>
                <ul>
                  <li> <span></span>{tour.meetingPoint}</li>
                </ul>
              </div>


              { tour.itinerary && <div className="single-tour-information-basic-section">
                <h5>Itinerary:</h5>
                <ul>
                  <li> <span></span>{tour.itinerary}</li>
                </ul>
              </div>}

              { tour.notes.length > 0 && <div className="single-tour-information-basic-section">
                <h5>Notes:</h5>
                {tour.notes.map((note,index) => (
                  <p style={{fontSize: '16px'}} key={index}> <span className='notes-span' ></span> {note}  </p>
                ))}
              </div>}

              <hr />

              <div className="single-tour-information-basic-section-note">
                <h6>please note:</h6>
                <p> {tour.noteMain} </p>
              </div>
            </div>

          </div>

          <div className="single-tour-information-included">
            <div className="single-tour-information-included-section">
              <h5>Price includes:</h5>
              <ul >
                { tour.included.map((inc) => (
                  <li key={inc}> <img src={yesIcon} alt="Yes Icon" /> <span>{inc}</span> </li>
                ))}
              </ul>
            </div>
            <div className="single-tour-information-included-section">
              <h5>Excluded:</h5>
              <ul >
              { tour.excluded.map((inc) => (
                  <li key={inc}> <img src={noIcon} alt="Yes Icon" /> <span>{inc}</span> </li>
                ))}
              </ul>
            </div>
          </div>

          <hr />

          <div ref={photosRef} className="single-tour-gallery">
            <div className="single-tour-gallery-title">
              <img src={galleryIcon} alt="Gallery Icon" />
              <h6>Photos</h6>
            </div>

            <ImageGallery items={images} />
          </div>

      </div>
    
    </div>
  )
}

export default SingleTour

